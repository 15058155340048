export default {
    primaryFont: 'Poppins',
    secondaryFont: 'Work Sans',
    codeFont: 'Fira Code',
    
    colors: {
        'activityBar.background': 'white',
        'activityBar.foreground': 'black',
        'editor.foreground': 'black',
        'editor.background': 'white',
        
    }
}