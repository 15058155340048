import css from "@emotion/css";

export default css`

    html, body, #root{
        width:100%;
        height:100%;
        margin:0;
        padding:0;
    }

`