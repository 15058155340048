import changeCurrentInlineStyle from './changeCurrentInlineStyle';

const inlineMatchers = {
  BOLD: /(?:^|\s|\n|[^A-z0-9_*~`])(\*{2}|_{2})((?!\1).*?)(\1)($|\s|\n|[^A-z0-9_*~`])/g,
  ITALIC: /(?:^|\s|\n|[^A-z0-9_*~`])(\*{1}|_{1})((?!\1).*?)(\1)($|\s|\n|[^A-z0-9_*~`])/g,
  CODE: /(?:^|\s|\n|[^A-z0-9_*~`])(`)((?!\1).*?)(\1)($|\s|\n|[^A-z0-9_*~`])/g,
  STRIKETHROUGH: /(?:^|\s|\n|[^A-z0-9_*~`])(~{2})((?!\1).*?)(\1)($|\s|\n|[^A-z0-9_*~`])/g
};

const handleInlineStyle = (editorState) => {
  const key = editorState.getSelection().getStartKey();
  const text = editorState.getCurrentContent().getBlockForKey(key).getText();
  const line = `${text} `;
  let newEditorState = editorState;
  Object.keys(inlineMatchers).some((k) => {
    const re = inlineMatchers[k];
    let matchArr;
    do {
      matchArr = re.exec(line);
      if (matchArr) {
          console.log(matchArr)
        newEditorState = changeCurrentInlineStyle(newEditorState, matchArr, k);
      }
    } while (matchArr);
    return newEditorState !== editorState;
  });
  return newEditorState;
};

export default handleInlineStyle;